import axios from 'axios';
import { getAuthHeader, getBaseURL, handleApiError } from './AxiosAuthService';

export const NotesService = {
    createStaffNotes: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/ProjectNotes?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, deleteStaffNotes: async <T>(notesId: any, tenantId: number, projectId: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/ProjectNotes/${notesId}?tenantId=${tenantId}&projectId=${projectId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateStaffNotes: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/ProjectNotes?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
}