import { Modal, SelectV2 } from '@dynatrace/strato-components-preview';
import { Flex, Button } from '@dynatrace/strato-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useTenants, useUserByEmailId } from './hooks/use-minerva-data';
import { ShowErrorNotification } from './utils/Notifications';
import { useState } from 'react';
import { UserService } from './services/UsersService';
import { useAppInfo } from './contexts/AppContext';

export interface TenantSelectionModalProps {
  close: () => void;
}

export const TenantSelectionModal = (props: TenantSelectionModalProps) => {
  const { user } = useAuth0();
  const [selectedTenant, setSelectedTenant] = useState<number | null>(null);
  const { tenantId } = useAppInfo();
  const { close } = props;

  const { data: tenants, error } = useTenants();
  if (error) {
    ShowErrorNotification('Error loading tenants', error);
  }

  const { data: thisUser } = useUserByEmailId(user?.email ?? '');
  if (error) {
    ShowErrorNotification('Error loading tenants', error);
  }

  const handleOpenTenant = async () => {
    // save user's last tenant as this tenant
    try {
      if (thisUser !== undefined && selectedTenant !== null) {
        thisUser.tenantId = selectedTenant;
        await UserService.updateUserById(thisUser, tenantId);
      }
      close();
    } catch (error) {
      ShowErrorNotification('Error opening tenant', error);
    }
  };

  return (
    <Modal
      show={true}
      title={`Minerva tenants`}
      size={'small'}
      dismissible={false}
      footer={
        <>
          <Flex justifyContent='center'>
            <Button width='100px' onClick={handleOpenTenant} variant='accent' disabled={selectedTenant === null}>
              OK
            </Button>
          </Flex>
        </>
      }
    >
      <Flex
        onKeyDown={(event: { key: string; preventDefault: () => void }) => {
          if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
          }
        }}
      >
        <SelectV2 onChange={setSelectedTenant}>
          <SelectV2.Trigger width='full' placeholder='Select tenant' />
          <SelectV2.Content>
            {tenants?.map((tenant) => (
              <SelectV2.Option key={tenant.tenantId} value={tenant.tenantId}>
                {tenant.tenantName}
              </SelectV2.Option>
            ))}
          </SelectV2.Content>
        </SelectV2>
      </Flex>
    </Modal>
  );
};
