import { format, parseISO } from 'date-fns';
import { StatusDetails } from '../../../../types/Status';
import { ColumnType, DataTable, TextEllipsis, Tooltip } from '@dynatrace/strato-components-preview';

// table main columns
export const columns = (onProjectRequestClick: (requestRow: any) => void) => [
  {
    id: 'projectRequestId',
    header: 'Request Id',
    accessor: 'projectRequestId',
    autoWidth: true,
    columnType: 'number' as ColumnType,
  },
  {
    id: 'projectRequestName',
    header: 'Request name',
    accessor: 'projectRequestName',
    width: 280,
    columnType: 'string' as ColumnType,
    Cell: ({ row }: any) => {
      const rowData = row.original;
      return (
        <DataTable.Cell>
          <Tooltip placement="bottom" text={rowData.projectRequestName}>
            <TextEllipsis
              onClick={() => {
                onProjectRequestClick(rowData);
              }}
              truncationMode="end"
              style={{
                color: 'var(--dt-colors-text-primary-default)',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              {rowData.projectRequestName}
            </TextEllipsis>
          </Tooltip>
        </DataTable.Cell>
      );
    },
  },
  {
    id: 'createdOn',
    header: 'Created on',
    accessor: 'createdOn',
    columnType: 'date' as ColumnType,
    width: 200,
    Cell: ({ value }: any) => {
      try {
        const date = parseISO(value);
        return format(date, 'MMM dd, yyyy HH:mm');
      } catch (error) {
        return 'Invalid date';
      }
    },
  },
  {
    id: 'assignedCount',
    header: 'Receipients count',
    accessor: 'assignedCount',
    autoWidth: true,
    columnType: 'number' as ColumnType,
  },
];

// table sub-row columns
export const rowColumns = [
  {
    id: 'assignedto',
    header: 'Assigned to',
    accessor: 'assignedto',
    minWidth: 300,
    columnType: 'text',
  },
  {
    id: 'completed',
    header: 'Completed',
    accessor: 'completed',
    width: 100,
    columnType: 'number',
  },
  {
    id: 'inProgress',
    header: 'In-complete',
    accessor: 'inProgress',
    width: 100,
    columnType: 'number',
  },
];

export const rowDocumentsColumns = [
  {
    id: 'label',
    header: 'File name',
    accessor: 'label',
    minWidth: 250,
    autoWidth: true
  },
  {
    id: 'instructions',
    header: 'Instructions',
    accessor: 'instructions',
    minWidth: 350,
    autoWidth: true
  },
  {
    id: 'statusName',
    header: 'Status',
    accessor: 'statusName',
    width: 150
  },
];
