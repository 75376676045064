import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL} from './AxiosAuthService';

export const BusinessService = {
  getBusinessList: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/business?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  createBusiness: async <T>(request: any, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.post(`${getBaseURL()}/business?tenantId=${tenantId}`, request, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getBusinessById: async <T>(id: number, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/business/${id}?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  updateBusinessById: async <T>(values: any, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.put(`${getBaseURL()}/business?tenantId=${tenantId}`, values, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getClinetDetailsById: async <T>(id: number, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(
        `${getBaseURL()}/peopleBusinessRelation/businessRelation/${id}?tenantId=${tenantId}`,
        getAuthHeader(),
      );
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getUserDetailsByTenantId: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/tenant/usersByTenant/${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getPeopleDetailsByTenantId: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/tenant/peoplesByTenant/${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
};

const handleApiError = (error: AxiosError) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
  } else if (error.request) {
    console.error('API No Response:', error.request);
  } else {
    console.error('API Error:', error.message);
  }
};
