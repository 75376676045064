import { Link, useLocation } from 'react-router-dom';
import { Button } from '@dynatrace/strato-components';
import { Flex } from '@dynatrace/strato-components';
import {
  AppsIcon,
  BusinessAnalyticsSignetIcon,
  ChartCollectionIcon,
  GroupIcon,
  MoneyIcon,
  TemplateIcon,
} from '@dynatrace/strato-icons';
import { useEffect, useState } from 'react';
import { configRoutes } from '../configs/constants';
import { Colors } from '@dynatrace/strato-design-tokens';

const menuIcons = [
  <ChartCollectionIcon />,
  <BusinessAnalyticsSignetIcon />,
  <MoneyIcon />,
  <GroupIcon />,
  <TemplateIcon />,
  <AppsIcon />,
];

export interface SidebarProps {}

export function Sidebar(props: SidebarProps) {
  const currentPath = useLocation();
  const pageIndex: number = configRoutes.findIndex((page) => page.routeName === currentPath.pathname);

  const [selectedPage, setSelectedPage] = useState<number>(pageIndex);

  // Update selectedPage when currentPath changes
  useEffect(() => {
    let newIndex: number = configRoutes.findIndex((page) => page.routeName === currentPath.pathname);
    newIndex = newIndex === -1 ? 0 : newIndex;
    setSelectedPage(newIndex);
  }, [currentPath]);

  // Sidebar content which is nothing but different
  // configuration pages - build it here and assign
  // in our sidebar content
  const sideBarContent = () => {
    return (
      <Flex flexDirection={'column'} gap={4} style={{ marginTop: '5px' }}>
        {configRoutes.map((page, index) => {
          return (
            index <= 2 && (
              <Link style={{ width: '100%' }} to={page.routeName} key={index}>
                <Button
                  textAlign='start'
                  width={'100%'}
                  onClick={() => {
                    setSelectedPage(index);
                  }}
                  color={'neutral'}
                  style={
                    selectedPage === index || (index === 0 && selectedPage === 4)
                      ? {
                          backgroundColor: Colors.Background.Field.Primary.Emphasized,
                          color: Colors.Text.Primary.Default,
                          borderRadius: 8,
                          padding: '6px 8px 6px 2px',
                        }
                      : {
                          backgroundColor: Colors.Background.Container.Neutral.Default,
                          color: Colors.Text.Neutral.Default,
                          borderRadius: 8,
                          padding: '6px 8px 6px 8px',
                        }
                  }
                >
                  <Flex flexDirection='row' gap={4}>
                    {selectedPage === index && (
                      <div
                        style={{
                          margin: 0,
                          padding: 0,
                          backgroundColor: Colors.Border.Primary.Accent,
                          borderRadius: 1,
                          width: '2px',
                          height: '20px',
                        }}
                      />
                    )}
                    {menuIcons[index]}
                    {page.friendlyName}
                  </Flex>
                </Button>
              </Link>
            )
          );
        })}
      </Flex>
    );
  };

  return <Flex flexDirection='column'>{sideBarContent()}</Flex>;
}
