import axios from 'axios';
import { getAuthHeader, getBaseURL,  handleApiError } from './AxiosAuthService';// Replace with your API endpoint

export const UploadService = {
    postProjectRequestUploadDetails: async <T>(token: any): Promise<T> => {
        try {
            const request = { token: token };
            const response: any = await axios.post(`${getBaseURL()}/ProjectRequest/projectRequestUploadDetails`, request);
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectRequestSubmissionEmail: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/ProjectRequest/postProjectRequestSubmissionEmail?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectRequestUploadFiles: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/ProjectRequest/projectRequestUploadFiles?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectRequestNotApplicableUpdate: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/ProjectRequest/postProjectRequestNotApplicableUpdate?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    projectRequestUploadFileForStaff: async <T>(request: any, tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/ProjectRequest/projectRequestUploadFileForStaff?tenantId=${tenantId}`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
}
