import axios from 'axios';
import { getAuthHeader, getBaseURL, handleApiError } from './AxiosAuthService';
import { DocumentType } from '../types/DocumentType';
import { RequestTemplate } from '../types/RequestTemplate';
import { Reminders } from '../types/Tenant';

export const TemplateService = {
  getReminderList: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/Reminder?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getReminderListByTenantId: async (tenantId: any): Promise<Reminders[]> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/Reminder/tenant/` + tenantId, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getDocumentList: async <T>(tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/documenttype?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getDocumentListByTenantId: async (tenantId: any): Promise<DocumentType[]> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/documenttype/tenantId/` + tenantId, getAuthHeader());
      return response.data.documentTypes;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  postRequestTemplates: async <T>(request: any, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.post(`${getBaseURL()}/requesttemplate?tenantId=${tenantId}`, request, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getProjectDocumentTemplates: async (id:number): Promise<RequestTemplate[]> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/requesttemplate/getByTenant/${id}`, getAuthHeader());
      return response.data.requestTemplates;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getRequestDetailsById: async <T>(id: number, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/requesttemplate/getTemplate/${id}?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  updateRequestTemplates: async <T>(request: any, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.put(`${getBaseURL()}/requesttemplate?tenantId=${tenantId}`, request, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  saveRequestDetailsTemplates: async <T>(request: any, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.post(`${getBaseURL()}/requesttemplatedetails?tenantId=${tenantId}`, request, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  updateRequestDetailsTemplates: async <T>(request: any, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.put(`${getBaseURL()}/requesttemplatedetails?tenantId=${tenantId}`, request, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  deleteRequestDetailsTemplates: async <T>(id: number, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.delete(`${getBaseURL()}/requesttemplatedetails/${id}?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  deleteRequestTemplate: async <T>(id: number, tenantId: number): Promise<T> => {
    try {
      const response: any = await axios.delete(`${getBaseURL()}/requesttemplatedetails/deleteTemplate/${id}?tenantId=${tenantId}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
};
