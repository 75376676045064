import {
  DataTable,
  FormField,
  Label,
  SelectV2,
  Sheet,
  Tab,
  TableColumn,
  Tabs,
  TextArea,
  TextInput,
  TitleBar,
} from '@dynatrace/strato-components-preview';
import { Button, Flex } from '@dynatrace/strato-components';
import { TextEllipsis } from '@dynatrace/strato-components';
import { XmarkIcon, DescriptionIcon, GroupIcon, PlusIcon } from '@dynatrace/strato-icons';
import { Key, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isArray } from 'lodash';
import { ItemInfo } from '../../types/ListItemInfo';
import { UserSheet } from '../user/UserSheet';
import { useAppInfo } from '../../contexts/AppContext';
import { useUsersByTenantId } from '../../hooks/use-minerva-data';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { formatDate } from '@dynatrace-sdk/units';
import { Tenant, TenantUser } from '../../types/Tenant';
import { TenantService } from '../../services/TenantService';

export interface TenantSheetProps {
  closeDetails: (itemInfo?: ItemInfo) => void;
  show: boolean;
  itemInfo?: ItemInfo;
}

export const TenantSheet = ({ closeDetails, show, itemInfo }: TenantSheetProps) => {
  const [showTenant, setShowTenant] = useState(show);
  const [userDetailsVisible, setUserDetailsVisible] = useState<ItemInfo | undefined>();
  const { tenantStateList, users, userId, tenantId, user: loggedInUser } = useAppInfo();
  const { isLoading, data: tenant, refetch, error } = useUsersByTenantId(itemInfo?.id ?? 0, tenantId);
  if (error) {
    ShowErrorNotification('Error getting tenant info', error);
  }

  if (userDetailsVisible?.refreshParent) {
    userDetailsVisible.refreshParent = false;
    refetch();
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<{
    tenantName: string;
    tenantDescription: string;
    tenantPhone: string;
    tenantContactEmail: string;
    tenantContactName: string;
    tenantDomain: string;
    tenantAddress: string;
    tenantAddress1: string;
    city: string;
    stateid: undefined | Key;
    postalCode: string;
    createdByName: string;
  }>({
    mode: undefined,
  });

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        id: 'userName',
        header: 'Name',
        accessor: 'userName',
        width: 300,
        cell: (cell: any) => (
          <DataTable.Cell>
            {
              <TextEllipsis
                onClick={() => {
                  setUserDetailsVisible({
                    name: `${cell.row.original.lastName}, ${cell.row.original.firstName}`,
                    idStr: cell.row.original.userId,
                    tenantName: tenant?.tenant.tenantName,
                    tenantId: tenant?.tenant.tenantId,
                    visibility: true,
                  });
                }}
                truncationMode='end'
                style={{
                  color: 'var(--dt-colors-text-primary-default',
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                }}
              >
                {`${cell.row.original.lastName}, ${cell.row.original.firstName}`}
              </TextEllipsis>
            }
          </DataTable.Cell>
        ),
      },
      {
        id: 'phoneNumber',
        header: 'Phone',
        accessor: 'phoneNumber',
        width: 150,
      },
      {
        id: 'email',
        header: 'E-mail',
        accessor: 'email',
        width: 200,
      },
      {
        id: 'roles',
        header: 'Tenant role',
        accessor: 'roles',
        width: 150,
        cell: (cell: any) => <DataTable.Cell>{getUserRole(cell.row.original)}</DataTable.Cell>,
      },
      {
        id: 'createTime',
        header: 'Created on',
        accessor: 'createTime',
        columnType: 'date',
        autoWidth: true,
        cell: (cell: any) => (
          <DataTable.Cell>{formatDate(new Date(cell.value).getTime(), { dateStyle: 'medium' })}</DataTable.Cell>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // only admin and tenant admin can add staff + they can edit other users info.
  // if you cannot add staff you can only edit your own info
  const cannotAddStaff = !(
    loggedInUser?.isAdminUser ||
    loggedInUser?.roles === 'Admin' ||
    loggedInUser?.roles === 'TenantAdmin'
  );

  const getUserRole = (user: TenantUser) => {
    if (user.roles === 'Admin') {
      return 'System Admin';
    }
    if (user.roles === 'Staff') {
      return 'Staff';
    }
    if (user.roles === 'TenantAdmin') {
      return 'Tenant Admin';
    }
    return 'Unknown';
  };

  // save the information
  const saveDetails = () => {
    if (isSubmitSuccessful) {
      // save the information and then close
      closeDetails();
    }
  };

  // discarded or closed the sheet from escape key
  const dismissDetails = () => {
    closeDetails();
  };

  useEffect(() => {
    if (itemInfo?.id) {
      const createdByUser = users?.find((user) => user.userId === tenant?.tenant?.createdBY);
      reset({
        tenantName: tenant?.tenant?.tenantName,
        tenantDescription: tenant?.tenant?.tenantDescription,
        tenantContactEmail: tenant?.tenant?.tenantContactEmail,
        tenantDomain: tenant?.tenant?.tenantDomain,
        tenantPhone: tenant?.tenant?.tenantPhone,
        tenantContactName: tenant?.tenant?.tenantContactName,
        tenantAddress: tenant?.tenant?.tenantAddress,
        tenantAddress1: tenant?.tenant?.tenantAddress1,
        city: tenant?.tenant?.city,
        stateid: tenant?.tenant?.stateid,
        postalCode: tenant?.tenant?.postalCode,
        createdByName:
          createdByUser?.lastName && createdByUser?.firstName
            ? `${createdByUser?.lastName}, ${createdByUser?.firstName}`
            : 'Not available',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant?.tenant]);

  const saveTenant = async (values: any) => {
    try {
      if (itemInfo?.id) {
        values.tenantId = itemInfo.id;
        values.updatedBY = userId;
        await TenantService.updateTenantById(values);
        ShowSuccessNotification('Tenant saved successfully');
      } else {
        values.createdBY = userId;
        const data: Tenant = await TenantService.createTenant(values);
        if (itemInfo) {
          itemInfo.id = data.tenantId;
          itemInfo.name = data.tenantName;
        }
        ShowSuccessNotification('Updated tenant successfully');
      }
    } catch (error) {
      ShowErrorNotification('Error saving tenant', error);
    }
    closeDetails({ visibility: false, refreshParent: true });
  };
  const title = itemInfo?.name ? itemInfo.name : 'New Tenant';
  const subTitle = itemInfo?.id ? 'Edit and manage tenant staff' : 'Add new tenant';
  return (
    <Sheet show={showTenant} onDismiss={dismissDetails}>
      <Flex flexDirection='column' margin={8} padding={0} gap={8}>
        <TitleBar>
          <TitleBar.Title>{title}</TitleBar.Title>
          <TitleBar.Subtitle>{subTitle} </TitleBar.Subtitle>
          <TitleBar.Action>
            <Flex flexDirection='row' gap={8}>
              <Button
                width='75px'
                onClick={() => {
                  setShowTenant(false);
                  dismissDetails();
                }}
                variant='default'
              >
                <Button.Prefix>
                  <XmarkIcon />
                </Button.Prefix>
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>
        {!isLoading && (
          <Flex flexDirection='column' gap={4}>
            <Tabs defaultIndex={0}>
              <Tab title='Details' prefixIcon={<DescriptionIcon />}>
                <LoadingStateComponent loading={isLoading} />
                <form onSubmit={handleSubmit(saveTenant)} onReset={() => reset()} noValidate>
                  <Flex flexDirection='column' justifyContent='space-between' alignItems='end'>
                    <TitleBar.Subtitle>
                      <TitleBar.Action>
                        <Button
                          style={{ marginRight: '8px' }}
                          width='75px'
                          onClick={() => {
                            setShowTenant(false);
                            dismissDetails();
                          }}
                          variant='default'
                        >
                          Discard
                        </Button>
                        <Button width='60px' onClick={() => saveDetails} type='submit' variant='accent' color='primary'>
                          Save
                        </Button>
                      </TitleBar.Action>
                    </TitleBar.Subtitle>
                  </Flex>
                  <Flex flexDirection='column'></Flex>
                  <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
                    <Controller
                      name='tenantName'
                      defaultValue={itemInfo?.name ?? ''}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Tenant name is required.',
                        },
                        maxLength: {
                          value: 200,
                          message: 'Please enter a valid tenant name.',
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormField required>
                          <Label>Tenant name</Label>
                          <TextInput
                            placeholder='Enter tenant name'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name='tenantDescription'
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Description is required',
                        },
                        maxLength: {
                          value: 250,
                          message: 'Description cannot have more than 250 characters.',
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormField required>
                          <Label>Description</Label>
                          <TextArea
                            aria-label='Description'
                            width={'100%'}
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name='tenantPhone'
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Mobile number is required.',
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormField required>
                          <Label>Mobile number</Label>
                          <TextInput
                            placeholder='Enter mobile number'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name='tenantContactEmail'
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'E-mail is required.',
                        },
                        maxLength: {
                          value: 200,
                          message: 'Please enter a valid email address',
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormField required>
                          <Label>E-mail</Label>
                          <TextInput
                            placeholder='Enter email'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name='tenantContactName'
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Contact name is required.',
                        },
                        maxLength: {
                          value: 200,
                          message: 'Please enter a contact name for the tenant.',
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormField required>
                          <Label>Contact name</Label>
                          <TextInput
                            placeholder='Enter contact name'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name='tenantDomain'
                      control={control}
                      rules={{}}
                      render={({ field, fieldState: { error } }) => (
                        <FormField>
                          <Label>Website name</Label>
                          <TextInput
                            placeholder='Enter website name'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name='tenantAddress'
                      control={control}
                      rules={{}}
                      render={({ field, fieldState: { error } }) => (
                        <FormField>
                          <Label>Street address</Label>
                          <TextInput
                            placeholder='Enter street address'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name='city'
                      control={control}
                      rules={{}}
                      render={({ field, fieldState: { error } }) => (
                        <FormField>
                          <Label>City</Label>
                          <TextInput
                            placeholder='Enter city name'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name='stateid'
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormField>
                          <Label>State</Label>
                          <Flex
                            onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                              if (event.key === 'Enter') {
                                event.preventDefault(); // Prevent form submission
                              }
                            }}
                          >
                            <SelectV2
                              controlState={{
                                state: error ? 'error' : 'valid',
                                hint: isArray(error) ? error.join('\n') : error?.message,
                              }}
                              {...field}
                            >
                              <SelectV2.Trigger placeholder={'Select state'} />
                              <SelectV2.Content style={{ width: 'max-content', minWidth: '200px' }}>
                                {tenantStateList &&
                                  tenantStateList.map((state, index) => (
                                    <SelectV2.Option key={index} value={state.id}>
                                      {state.name}
                                    </SelectV2.Option>
                                  ))}
                              </SelectV2.Content>
                            </SelectV2>
                          </Flex>
                        </FormField>
                      )}
                    />
                    <Controller
                      name='postalCode'
                      control={control}
                      rules={{}}
                      render={({ field, fieldState: { error } }) => (
                        <FormField>
                          <Label>Zip code</Label>
                          <TextInput
                            placeholder='Enter zip code'
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: error?.message,
                            }}
                            {...field}
                          />
                        </FormField>
                      )}
                    />

                    {itemInfo?.id && (
                      <Controller
                        name='createdByName'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <FormField>
                            <Label>Created by</Label>
                            <TextInput disabled {...field} />
                          </FormField>
                        )}
                      />
                    )}
                  </Flex>
                </form>
              </Tab>
              <Tab prefixIcon={<GroupIcon />} title='Manage Staff'>
                <Flex flexDirection='column' gap={16}>
                  <Flex flexDirection='column' justifyContent='space-between' alignItems='end'>
                    <TitleBar.Subtitle>
                      <TitleBar.Action>
                        <Button
                          width='100px'
                          type='submit'
                          variant='accent'
                          color='primary'
                          disabled={cannotAddStaff}
                          onClick={() => {
                            setUserDetailsVisible({
                              tenantName: tenant?.tenant.tenantName,
                              tenantId: tenant?.tenant.tenantId,
                              visibility: true,
                            });
                          }}
                        >
                          <Button.Prefix>
                            <PlusIcon />
                          </Button.Prefix>
                          Staff
                        </Button>
                      </TitleBar.Action>
                    </TitleBar.Subtitle>
                  </Flex>

                  <DataTable
                    loading={isLoading}
                    columns={columns}
                    data={tenant?.users ?? []}
                    sortable
                    variant={{ rowDensity: 'default' }}
                    sortBy={{ id: 'userName', desc: false }}
                  />
                  {userDetailsVisible && userDetailsVisible.visibility && (
                    <UserSheet
                      closeDetails={(itemInfo?: ItemInfo) => {
                        //reload the page as needed
                        setUserDetailsVisible(itemInfo);
                      }}
                      show={true}
                      itemInfo={userDetailsVisible}
                    />
                  )}
                </Flex>
              </Tab>
            </Tabs>
          </Flex>
        )}
      </Flex>
    </Sheet>
  );
};
