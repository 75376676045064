// src/services/apiService.ts
import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL } from './AxiosAuthService'; // Replace with your API endpoint
import { User } from '../types/User';
 
export const UserService = {
    createuser: async <T>(request: any, tenantId: number): Promise<T> => {
      try {
        const response: any = await axios.post(`${getBaseURL()}/user?tenantId=${tenantId}`,request, getAuthHeader());
        return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getUsers: async (tenantId: number): Promise<User[]>=>{
      try{
          const response: any = await axios.get(`${getBaseURL()}/user?tenantId=${tenantId}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getUserById: async <T>(id:string, tenantId: number): Promise<T>=>{
      try{
          const response: any = await axios.get(`${getBaseURL()}/user/${id}?tenantId=${tenantId}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getUserByUserName: async (userName:string): Promise<User>=>{
      try{
          const response: any = await axios.get(`${getBaseURL()}/user/getuser/${userName}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    }
    ,updateUserById: async <T>(values:any, tenantId: number): Promise<T>=>{
      try{
          const response: any = await axios.put(`${getBaseURL()}/user?tenantId=${tenantId}`,values, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getUserListByTenantId: async <T>(id:number, tenantId: number): Promise<T>=>{
      try{
          const response: any = await axios.get(`${getBaseURL()}/tenant/usersByTenant/${id}?tenantId=${tenantId}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },verifyIsEmailExist: async <T>(email: string, tenantId: number): Promise<boolean> => {
      try {
        const response: any = await axios.get(`${getBaseURL()}/user/isUserNameAvailable/${email}?tenantId=${tenantId}`, getAuthHeader());
        return response.data;
      } catch (error: any) {
        handleApiError(error);
        throw error;
      }
    },forgotPassword: async <T>(values: any, tenantId: number): Promise<T> => {
      try {
        const response: any = await axios.get(`${getBaseURL()}/user/forgotPassword/${values}?tenantId=${tenantId}`, getAuthHeader());
        return response.data;
      } catch (error: any) {
        handleApiError(error);
        throw error;
      }
    }
  };
  
  const handleApiError = (error: AxiosError) => {
    if (error.response) {
      console.error('API Error Response:', error.response.data);
    } else if (error.request) {
      console.error('API No Response:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
  };
  