import { useState } from 'react';
// import { ShowErrorNotification } from '../../utils/Notifications';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { useUploadDetails } from '../../hooks/use-minerva-data';
import { ToastContainer } from '@dynatrace/strato-components-preview/notifications';
import { Page } from '@dynatrace/strato-components-preview/layouts';
import { LandingHeader } from './LandingHeader';
import { MainContent } from './MainContent';
import { ErrorPage } from '../../components/PageNotFound';
import { Flex } from '@dynatrace/strato-components';

export interface LandingPageProps {
  token: string;
}

export const LandingPage = (props: LandingPageProps) => {
  const { token: uploadToken } = props;


  const [expandedSections, setExpandedSections] = useState<{ [key: number]: boolean }>({}); // Track expanded state for each section

  // get upload details for the given token!
  const {
    isLoading,
    data: requestUploadDetails,
    error: requestUploadError,
    refetch: refetchRequestUpload,
    isRefetching: isRefetchRequestUploadLoading
  } = useUploadDetails(uploadToken);

  if (requestUploadError) {
    return (
      <Page>
        <Page.Main>
          <ErrorPage />
        </Page.Main>
      </Page>
    );
  }

  const incompleteTasks =
    requestUploadDetails?.projectRequestDetails.filter(
      (reqItem) => reqItem.statusName === 'New' || reqItem.statusName === 'Rejected',
    ) ?? [];

  const completedTasks =
    requestUploadDetails?.projectRequestDetails.filter(
      (reqItem) => reqItem.statusName === 'Approved' || reqItem.statusName === 'Pending Approval' || reqItem.statusName === 'Not Applicable',
    ) ?? [];

  // Toggle the expand/collapse state for a specific section
  const toggleExpand = (index: number) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (

    <Flex>
      <LoadingStateComponent loading={isLoading || isRefetchRequestUploadLoading} />
      <Page>
        {/* Header */}
        <ToastContainer />
        <Page.Header>
          <LandingHeader tenantName={requestUploadDetails?.tenant.tenantName ?? ''} />
        </Page.Header>
        {/* Main Content */}
        <Page.Main>
          <MainContent
            completedTasks={completedTasks}
            incompleteTasks={incompleteTasks}
            expandedSections={expandedSections}
            toggleExpand={toggleExpand}
            reloadUploadDetails={refetchRequestUpload}
            uploadFileKeys={{
              token: uploadToken,
              fileFormat: requestUploadDetails?.fileFormat,
              projectId: requestUploadDetails?.projectRequest?.projectId,
              peopleId: requestUploadDetails?.people?.clientId,
              peopleName: requestUploadDetails?.people?.firstName + ' ' + requestUploadDetails?.people?.lastName,
              request: requestUploadDetails?.projectRequest,
              tenantId: requestUploadDetails?.tenant?.tenantId,
            }}
          />
        </Page.Main>
      </Page>
    </Flex>

  );
};

export default LandingPage;
