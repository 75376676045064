import { DataTable, Modal, uuidv4 } from '@dynatrace/strato-components-preview';
import { Button, Flex, } from '@dynatrace/strato-components';
import { useMemo, useRef, useState } from 'react';
import { Business } from '../../../types/Business';
import { ProjectBusinessRelation } from '../../../types/Project';
import { PlusIcon } from '@dynatrace/strato-icons';
import { NewBusinessModal } from '../../business/NewBusinessModal';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../utils/Notifications';
import { ProjectService } from '../../../services/ProjectService';
import { useAppInfo } from '../../../contexts/AppContext';
import { useBusinessesByTenantId } from '../../../hooks/use-minerva-data';
import { ModalParentType, PARENT_TYPE_NONE } from '../../../types/Types';

interface NewProjectBusinessRelationModalProps {
  parentType: ModalParentType;
  onDismiss: (refresh?: boolean) => void;
  projectId: number;
  currentProjectBusinessList: ProjectBusinessRelation[];
}

export const NewProjectBusinessRelationModal = (props: NewProjectBusinessRelationModalProps) => {
  const { onDismiss, projectId } = props;
  const [showModal, setShowModal] = useState(true);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [showNewBusinessModal, setShowNewBusinessModal] = useState<boolean>(false);

  const { tenantId, tenantBusinessCategories } = useAppInfo();
  const businessIds = useRef<number[]>([]);

  const { isLoading, error, data: tenantBusinesses, refetch } = useBusinessesByTenantId(tenantId);
  if (error) {
    ShowErrorNotification('Error loading business list', error);
  }

  const businessCategoryMap = useMemo(() => {
    const map: Record<number, string> = {};
    tenantBusinessCategories?.forEach((category) => {
      map[category.businessCategoryAutoId] = category.businessCategory;
    });
    return map;
  }, [tenantBusinessCategories]);
  

  const projectBusinessColumns = useMemo(
    () => [
      {
        id: 'businessName',
        header: 'Business name',
        accessor: 'businessName',
        width: 300,
      },
      {
        id: 'businessCategory',
        header: 'Business Category',
        accessor: (row: Business) =>
          businessCategoryMap[row.businessCategoryId] || '',
        minWidth: 200,
        autoWidth: true,
      },
    ],
    [businessCategoryMap],
  );

  const handleCancel = () => {
    setShowModal(false);
    onDismiss();
  };

  const handleConfirm = () => {
    saveProjectBusinessRelation();
    setShowModal(false);
    onDismiss();
  };

  // save new business - project relations and close the modal
  // with refresh flag to project
  const saveProjectBusinessRelation = async () => {
    try {
      const requestData = businessIds.current.map((businessId) => ({
        projectBusinessId: 0,
        tenantId: tenantId,
        projectId: projectId,
        businessId,
        primaryForLoan: 0,
        modifiedOn: new Date().toISOString(),
        modifiedBy: '',
      }));
      await ProjectService.postProjectBusinessesRelationWithDetails(requestData, tenantId);
      ShowSuccessNotification('Project business relation created successfully');
      onDismiss(true);
    } catch (error) {
      ShowErrorNotification('Error creating project business relation', error);
      onDismiss();
    }
  };

  const onRowSelected = (obj: Record<string, boolean>, data: any[], trigger: 'user' | 'internal') => {
    setDisableConfirm(data.length === 0);
    businessIds.current = [];
    data.forEach((b: Business) => {
      businessIds.current.push(b.businessId);
    });
  };

  return (
    <Modal
      show={showModal}
      title={'Add business to this project'}
      size={'medium'}
      dismissible={false}
      footer={
        <Flex justifyContent='space-between' width='100%'>
          <Button
            onClick={() => {
              setShowNewBusinessModal(true);
            }}
            variant='emphasized'
            color='neutral'
          >
            <Button.Prefix>
              <PlusIcon />
            </Button.Prefix>
            New business
          </Button>
          <Flex justifyContent='flex-end' gap={8}>
            <Button onClick={handleCancel} variant='default'>
              Discard
            </Button>
            <Button onClick={handleConfirm} type='submit' variant='accent' color='primary' disabled={disableConfirm || props.parentType === PARENT_TYPE_NONE}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      }
    >
      <DataTable
        loading={isLoading}
        sortable
        columns={projectBusinessColumns}
        data={
          tenantBusinesses
            ? tenantBusinesses.filter(
                (business) => !props.currentProjectBusinessList.some((pbr) => pbr.businessId === business.businessId),
              )
            : []
        }
        variant={{ rowSeparation: 'zebraStripes' }}
        sortBy={{ id: 'businessName', desc: false }}
        selectableRows
        onRowSelectionChange={onRowSelected}
      >
        <DataTable.EmptyState>No businesses available.</DataTable.EmptyState>
      </DataTable>
      {showNewBusinessModal && (
        <NewBusinessModal
          key={uuidv4()}
          onDismiss={(refresh) => {
            setShowNewBusinessModal(false);
            if (refresh) {
              refetch();
            }
          }}
        />
      )}
    </Modal>
  );
};
