import { Surface } from '@dynatrace/strato-components/layouts';
import { Flex } from '@dynatrace/strato-components/layouts';
import { StatusConfig } from './status-config';
import { UploadRequestDetails } from '../../types/Request';
import { Strong, Text } from '@dynatrace/strato-components/typography';

export interface CompletedTasksProps {
  completedTasks: UploadRequestDetails[];
  toggleExpand: (projectRequestSentId: number) => void;
}

export const CompletedTasks = (props: CompletedTasksProps) => {
  const { completedTasks } = props;
  return (
    <Flex flexDirection='column' gap={12}>
      <h3>Completed Tasks</h3>
      {completedTasks.map((task, index) => (
        <Surface
          style={{
            backgroundColor: StatusConfig[task.statusName as keyof typeof StatusConfig].backgroundColor,
            color: StatusConfig[task.statusName as keyof typeof StatusConfig].color,
          }}
          key={index}
          elevation='floating'
          height={'auto'}
        >
          <Flex alignItems='center' justifyContent='space-between'>
            <Text as='h4'>{task.label}</Text>
            <Strong>
              <Text as='h4'>{task.statusName === 'Pending Approval' ? 'Pending Approval' : task.statusName}</Text>
            </Strong>
          </Flex>
        </Surface>
      ))}
    </Flex>
  );
};
